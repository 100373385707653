let tagged_messages = {};

let message = (m,type,tag) => {
    if (typeof(m)!="string" && m instanceof Object){
        let new_m = "";
        console.log("message", m);
        for (let p in m)
            new_m += p+": "+m[p]+"<br>";
        m = new_m;
    }
    type = type || "log";
    m = m.replace(/\[a\s([^\s]+)\s([^\]]+)\]/,"<a data-turbolinks='false' href='$1'>$2</a>")
    if (tagged_messages && tagged_messages.hasOwnProperty(tag) && tagged_messages[tag] instanceof jQuery)
        tagged_messages[tag].remove();
    let container = $("<div>").addClass("message").addClass(type)
                            .attr("title",`${(new Date()).getHours()}:${String((new Date()).getMinutes()).replace(/^(\d)$/,"0$1")}`);
    let close = $("<div>X</div>").addClass('close').click(()=>container.remove());
    let content = $("<div>").addClass("content").addClass(type).html(m);
    $('#message-container').append(container.append(close).append(content));
    if (tag)
        tagged_messages[tag] = container;
    return container;
};

var Message = {
    log: (m,tag) => message(m,'log',tag),
    error: (m,tag) => message(m,'error',tag),
    warning: (m,tag) => message(m,'warning',tag)
};

export { Message };
