import { Uploader } from '../shared/uploader';
import { Message } from '../shared/modular_messages';

const close_modular = id => {
    if (typeof id == "string" && id.length){
        const modular = $(`#modulars #${id}:visible`);
        if (modular.length) $("#modulars, #modulars .modular").hide();
    }
    else
        $("#modulars, #modulars .modular").hide();
}

// Move this into a shared module
const show_modular = id => {
    $("#modulars .modular").hide();
    $(`#modulars #${id}, #modulars`).show();
    const firstinput = $(`#modulars #${id}`).find("input[type=text], input[type=password]");
    if (firstinput.length) $(firstinput[0]).focus();
};

$(document).on('turbolinks:load', ()=>{
    $("#modulars, #modulars #closemodulars").mousedown( e => {
        const visiblemodulars = $(`#modulars .modular:visible`);
        if (visiblemodulars.length && visiblemodulars.find(".warning").length) return false;
        close_modular();
    });
    $("#modulars .modular").mousedown( e => e.stopPropagation() );

    $(".modular-link").click(function(e){
        e.preventDefault();
        const target = $(this).attr("target");
        if (target) show_modular(target);
        return false;
    });

    $(".async .submit").click( e => {
        e.preventDefault();
        let form = e.target;
        while (form.nodeName != "FORM" && form.nodeName != "HTML") form = form.parentElement;
        if (form.nodeName=="FORM"){
            let method = $(form).attr("method");
            const request = {};
            const fd = new FormData( form );
            for (let e of fd){
                let keys = e[0].replace(/\]/g,'').split('[');
                let currentEntry = request;
                while (keys.length>1){
                    if (!currentEntry.hasOwnProperty(keys[0])) currentEntry[keys[0]] = {};
                    currentEntry = currentEntry[keys[0]];
                    keys.shift();
                }
                currentEntry[keys[0]] = e[1];
                if (currentEntry==request&&keys[0]=="_method"&&e[1].match(/patch|put|get|post/i)) method = e[1];
            }   
            method = method.toUpperCase()
            console.log("request", request, "action", form.action, "method", method);
            Uploader.request_json( request , form.action , method ).success( r => {
                const modularParent = $(e.target).parents(".modular:visible");
                if (modularParent.length) close_modular();
                if (r.message) Message.log( r.message , form.id );
            }).error( r => (r.message||r.error?Message.error( r.message||r.error , form.id ):null) );
        }
        return false;
    });

});

var Modular = { show: show_modular , close: close_modular };

export { Modular };
