import { Uploader } from '../shared/uploader';
import { Message } from '../shared/modular_messages';

const logincallbacks = [];
const logoutcallbacks = [];

let init = u => {
    var $ = window.$;
    if (u && u.name){
        const newlogin = u.name != $("#user").data("name");
        $("#user").data(u);
        $(".loggedout").hide();
        $(".loggedin").show();
        if (newlogin)
            logincallbacks.map( f=>f instanceof Function && f.call(null, u) )
        // $("#menu .circle .prec").html(`${Math.trunc(100*u.cached_size/u.quota)}%`);
        // $("#menu .username").empty().html(u.name);
        // $("#menu .popin .more .storage").html(`${Math.round(u.cached_size/1000000,1)}/${u.quota/1000000}MB`);
        // $("#menu .popin .more .nxp a").html(`${u.nexps} Xps`);
        $("#home").text("Projects");
        const quotaPercent = Math.round(100*u.cached_size/u.quota);
        $("#percent").empty().append($(`<div class='percent'>${Math.round(u.cached_size/10000)/100}/${u.quota/1000000}MB (${quotaPercent}%)</div>`))
            .css("background",`linear-gradient(to right, rgb(0,200,0), rgb(${quotaPercent*2},${200-quotaPercent*2},0) ${quotaPercent}%, transparent ${quotaPercent}%)`);
        $("#profile").empty().append($(`<div class='name'>${u.name}</div>`));
    }
    else{
        logoutcallbacks.map( f=>f instanceof Function && f.call(null, $("#user").data()) )
        $("#user").removeData();
        $(".loggedout").show();
        $(".loggedin").hide();
        $("#home").text("Home");
        if ($("#experiment").length || window.location.href.match(/\/profile$/)){
            if ($("#experiment").data("user_id") || window.location.href.match(/\/profile$/))
                return window.location.assign("/");
            let xpsize =  $("#experiment").data('size') || 0;
            console.log("Init user with xp size", xpsize);
            $("#menu .circle .prec").html(`${Math.trunc(100*xpsize/$("#Root").data().quota)}%`);
            $("#menu .username").empty().html("Menu");
            $("#menu .popin .more .storage").html(`<p>${Math.round(xpsize/1000000)}/${$("#Root").data().quota/1000000}MB</p>`);
        }
    }
};

export var User = {
    init: u=>init(u),
    logout: ()=>init(null),
    login: u=>init(u),
    onlogin: f=>logincallbacks.push(f),
    onlogout: f=>logoutcallbacks.push(f)
};

$(document).on('turbolinks:load', ()=> {
    init($("#user").data());
    
    if ($("#user").data("id") && $("#user").data("email").length==0)
        Message.warning( "<a href='/profile'>Please associate an email with this account</a>" , 'user' );
    else if ($("#user").data("id") && $("#user").data("activated_at").length==0)
        Message.warning( "Please check your emails to verify this account" , 'user' );

    Uploader.then( response=> {
        console.log("response:",response);
        if (response.hasOwnProperty('user')){
            const user_data = JSON.parse(response.user);
            if (response.hasOwnProperty('experiment') && $("#experiment").length){
                const xp_data = JSON.parse(response.experiment);
                console.log("Checking xp_data", xp_data, $("#experiment").data("cached_size"));
                const difference = $("#experiment").data("cached_size") - xp_data.cached_size;
                user_data.cached_size += difference;
                console.log("Now user_data", user_data);
            }
            init(user_data);
        }
    });
    
});
